<template>
  <div class="Locked">
    <div class="titleBox d-flex align-items-center">
      <div class="imgBox5" @click="$router.go(-1)">
        <img src="../../assets/images/login/chevron-left.png" alt="" />
      </div>
      <div class="title">Locked</div>
    </div>

    <div class="mt-4">
      <div class="contentBox">
        <div class="d-flex justify-content-center">
          <img src="../../assets/images/login/locked.png" alt="" />
        </div>
        <div class="emailAddress">Email：{{this.$route.query.email}}</div>
        <div class="desc">* {{ $t("AuthPage.8e7@too_many_fails_login") }}</div>
        <div class="d-flex justify-content-end">
          <router-link :to="{ name: 'auth1.accountRecoveryBefore' }">
            <button
              type="submit"
              class="btn btn-primary float-right"
              @click="handleClickLogin"
            >
              Apply for unlocking
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Locked',
  data: () => ({}),
  mounted () { },
  methods: {
    // 申请解锁
    handleClickLogin(){},
  }
}
</script>
<style lang="scss" scoped>
@media (max-width: 578px) {
  .Locked {
    padding-left: 15px;
  }
}
.Locked {
  .titleBox {
    margin-bottom: 83px;
    position: relative;
    margin-top: 20px;
    .imgBox5 {
      position: absolute;
      left: -10px;
      cursor: pointer;
    }
    .title {
      font-size: 28px;
      font-weight: 400;
      padding-left: 20px;
      color: #3f414d;
    }
  }
  .contentBox {
    .emailAddress {
      margin: 67px 0 30px 0;
      text-align: center;
    }
    .desc {
      color: #ef5959;
      margin-bottom: 123px;
    }
  }
}
</style>
